<template>
  <div class="children">
    <div class="title">
      <h1>儿童个人信息保护指引</h1>
    </div>
    <p class="bold">导言</p>
    <div>
      青点互动（以下简称“我们”）尊重并保护应用的儿童个人信息，在使用我们的服务时，我们将按照法律法规《儿童个人信息网络保护协议》（以下简称“本文”）收集和使用您的个人信息。
    </div>
    <div>
      您应当在仔细阅读、充分理解本文，且征得您的监护人的同意后，方可使用我们的服务。当您在启动弹窗处选择并点击【同意】时，即表示您已理解本文且已征得您的监护人的同意，并同意我们按照本文规定的方式对您的信息进行收集和使用。如对本文内容有任何疑问、意见或建议，您可通过青点互动平台提供的各种方式联系我们。
    </div>
    <div>本文将帮助您了解以下内容：</div>
    <div>一、我们如何收集您的个人信息</div>
    <div>二、我们如何使用Cookie和同类技术</div>
    <div>三、我们如何使用您的个人信息</div>
    <div>四、我们如何保护您的个人信息</div>
    <div>五、如何访问、更正、删除您的个人信息</div>
    <div>六、隐私政策的更新</div>
    <div>七、如何保护您孩子的个人信息</div>
    <br />
    <br />
    <div class="bold">一、我们如何收集您的个人信息</div>
    <div class="bold">
      个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反应特定自然人活动情况的各种信息。个人信息包括：
    </div>
    <div>
      个人基本资料（个人姓名、性别、民族、国籍、住址、出生日期、身份证号码、电话号码、电子邮箱等）；个人身份信息（身份证、社保卡、居住证等）、个人生物识别信息（指纹、声纹、掌纹、面部特征等）；网络身份标识信息（系统账号、IP地址、邮箱地址及其与前述有关的密码、口令、口令保护答案、用户个人数字证书等）；
    </div>
    <div>
      个人财产信息（银行账号及密码、征信信息、交易和消费记录、流水记录等，以及虚拟货币、虚拟交易、游戏类兑换码等虚拟财产信息）；
    </div>
    <div>
      个人通信信息（通信通讯联系方式、通信记录和内容、电子邮件、短彩信等）、联系人信息（通讯录、好友列表、群列表、电子邮件地址列表等）；
    </div>
    <div>
      个人上网记录（指通过日志存储的用户操作记录，包括网站浏览记录、软件使用记录等）、个人常用设备信息（包括硬件序列号、设备MAC地址、软件列表、唯一设备识别码[如IMEI/android
      ID/IDFA/OPENUDID/GUID\SIM卡IMSI信息等]）；
    </div>
    <div>个人位置信息（如行踪轨迹、精准定位信息等）；</div>
    <div>其他信息。</div>
    <div class="bold">
      个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，个人敏感信息包括：身份证及身份证号码、手机号码、银行账号及密码、系统账号及密码、邮箱地址及密码、消费记录、地理位置信息等。
    </div>
    <div>
      在您使用青点互动服务的过程中，我们会按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保障您的账户安全：
    </div>
    <div class="bold">1.1注册及会员功能</div>
    <div>
      1.1.1当您使用注册及会员功能时，我们会收集您的手机号码、手机验证码匹配结果，用于为您创建青点互动平台账号、密码。
    </div>
    <div>
      1.1.2为了更好地为您提供个性化服务，您可在注册时或后续使用过程中主动补充填写您的性别、生日、昵称、头像。
    </div>
    <div>
      1.1.3若您以其他方式关联登录青点互动平台，我们会向关联第三方请求您的个人信息。
    </div>
    <div>
      1.1.4手机号码属于个人敏感信息，我们收集此类信息是为了满足相关法律法规的网络实名制要求。我们会在获得您和您的监护人同意或您和您的监护人主动提供的情况下收集您的实名身份信息，该信息属于敏感信息，拒绝提供实名身份信息可能会导致您无法登陆或在使用过程中受到相应限制。
    </div>
    <div>
      1.1.5当您进行付费、会员充值功能时，我们会收集您的余额、充值和使用情况、第三方交易凭证信息。
    </div>
    <div class="bold">1.2 作品展示及浏览功能</div>
    <div>
      1.2.1当您使用作品展示及浏览功能时，我们需要收集您的设备型号及制造商、设备屏幕信息、设备唯一标识符、浏览器类型和设置、语言设置、操作系统和应用程序版本、登录IP地址、接入网络的方式、网络状态、运营商类型、产品版本号、日志信息（如操作日志、服务日志）、用户ID、和作品浏览相关信息、图库。我们收集这些信息是为了向您提供我们最核心的作品展示及浏览、信息展示服务，如您拒绝提供可能导致您无法使用我们的服务。
    </div>
    <div>
      1.2.2
      我们会用1.2.1中收集到的您的个人信息，在首页、发现页、社区进行个性化展示、推荐或者用于您的用户画像，以向您推荐您感兴趣的内容。这些信息，我们不会用于其他用途，且我们对收集到的您的个人信息已进行加密处理，因为不会对您的个人信息安全产生影响。
    </div>
    <div class="bold">1.3搜索功能</div>
    <div>
      1.3.1当您使用搜索功能时，我们需要收集您使用的搜索词、用户ID信息。我们收集这些信息是为了向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们向您提供的服务。
    </div>
    <div>
      个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，个人敏感信息包括：身份证及身份证号码、手机号码、银行账号及密码、系统账号及密码、邮箱地址及密码、消费记录、地理位置信息等。
    </div>
    <div class="bold">1.4社区发贴、作品评论</div>
    <div>
      1.4.1当您使用社区发图文贴、作品评论时，需要调取您硬件设备的麦克风、相机、媒体库、存储卡修改或删除权限，如您拒绝提供，会使您无法使用上述功能；
    </div>
    <div>
      1.4.2您上传的文字、照片、评论、点赞、转发等信息会存储在我们的服务器中，因为存储是实现上述功能所必需的，您可以随时删除上述信息；
    </div>
    <div>
      1.4.3当您在社区发帖时，如使用定位功能，我们需要收集您的地理位置信息，地理位置信息属于敏感信息，收集上述信息为实现产品功能所必须，如您拒绝提供可能导致您无法使用我们的服务；
    </div>
    <div>
      1.4.4在帖子详情页时进行展示手机号（如需要实名认证）；在帖子详情页时，如需图片保存的，需要调取您的相机权限，如您拒绝提供可能导致图片无法保存。
    </div>
    <div class="bold">1.5编辑头像、编辑封面功能</div>
    <div>
      1.5.1当您使用编辑头像、编辑封面功能时，需要调取您硬件设备的相机权限，如您拒绝提供，会使您无法使用上述功能。
    </div>
    <div>
      1.5.2当您更换头像时，我们会调取您的存储卡的修改或删除权限。如您拒绝，会使您无法使用上述功能。
    </div>
    <div class="bold">1.6广告功能</div>
    <div>
      1.6.1当您使用广告功能时，我们需要收集您的设备标识、浏览内容、浏览广告的次数以及与它们互动的次数信息。我们收集这些信息是为了向您推荐更优质、您可能更感兴趣的广告内容。
    </div>
    <div class="bold">1.7意见反馈功能</div>
    <div>
      1.7.1联系方式（电话、QQ、邮箱）、反馈意见、用户ID、设备型号、屏幕分辨率、操作系统信息。
    </div>
    <div class="bold">1.8网络诊断功能</div>
    <div>
      1.8.1产品版本信息、用户ID、设备型号与制造商、设备唯一标识符、浏览器类型和设置操作系统和应用程序版本、接入网络的方式、网络状态、运营商类型、本地DNS网关信息、本机IP。
    </div>
    <div class="bold">1.9缓存下载功能</div>
    <div>
      1.911.1当您需要进行图片、视频等下载的，我们需要写入并读取您的存储卡权限，以保证您成功下载需要的图片、视频等。如您拒绝，会使您无法实现上述功能。
    </div>
    <div>
      1.9.2
      当您查看大图页面进行图片下载或在配音短视频播放页进行视频下载时，我们需要修改或者删除您的存储卡中的内容。如您拒绝，会使您无法实现上述功能。
    </div>
    <div class="bold">1.10 经您和您的监护人同意后收集的其他信息。</div>
    <br />
    <div class="bold">二、我们如何使用Cookie和同类技术</div>
    <div>
      2.1为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
      的小数据文件。Cookie
      通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。
    </div>
    <div>
      2.2在您使用我们的服务时，我们可能会使用Cookie或同类技术收集您的一些个人信息，例如：您访问网站的习惯、您的登录信息等，Cookie和同类技术收集该类信息是为了您使用我们的服务的必要、简化您重复操作的步骤（如注册、登录）、便于您查看使用历史（如浏览历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信息和账号安全性、改善我们的产品和服务等。我们承诺仅在本隐私政策所述目的范围内使用Cookie和同类技术。
    </div>
    <div>
      2.3如果您的浏览器允许，您可以通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie或同类技术文件，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。
    </div>
    <br />
    <div class="bold">三、我们如何使用您的个人信息</div>
    <div class="bold">3.1共享</div>
    <div>
      您的个人信息是我们为您提供服务的重要依据和组成部分，对于您的个人信息，我们仅在本文所述的目的和范围内或根据法律法规的要求收集和使用，并严格保密，我们不会向第三方共享您的个人信息，除非存在以下一项或多项情形：
    </div>
    <div class="bold">3.1.1事先征得您和您的监护人的明确授权同意；</div>
    <div>3.1.2您和您的监护人主动提出的要求；</div>
    <div>
      3.1.3根据法律法规规定、诉讼争议解决需要，或者行政、司法机关依照法律法规提出的要求；
    </div>
    <div>
      3.1.4将您的个人信息与青点互动的关联方共享。但青点互动只会共享必要的个人信息，且受本文的约束。青点互动的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
    </div>
    <div>
      3.1.5为了保护您、青点互动、其他用户的权利及财产安全或社会公共利益使其免遭损害而与第三方的共享；
    </div>
    <div>
      <span class="bold"
        >3.1.6与部分业务合作伙伴的必要共享：您理解并知悉，为了必要且合理的业务的顺利展开，履行我们在相关用户协议或本隐私政策中的义务，我们可能会向合作伙伴等第三方共享您的信息（详情见第八条关于“第三方SDK信息公示”内容）。</span
      >但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。对我们与之共享个人信息的公司、组织和个人，我们会要求其遵守保密约定，要求他们按照青点互动的说明、本文以及其他任何相关的保密和安全措施来处理个人信息。
    </div>
    <div>3.1.7其他法律法规等规定，司法机关或行政机关要求的情形。</div>
    <div class="bold">3.2转让</div>
    <div>
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </div>
    <div class="bold">3.2.1事先获得您和您的监护人的明确授权或同意；</div>
    <div>3.2.2为满足法律法规、程序的要求或强制性的政府要求或司法裁定；</div>
    <div>
      3.2.3如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移过程中的保密性，并尽最大可能确保新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求您的授权同意。
    </div>
    <div class="bold">3.3公开披露</div>
    <div class="bold">我们仅会在以下情况下，公开披露您的个人信息：</div>
    <div class="bold">3.3.1获得您和您的监护人的明确同意；</div>
    <div class="bold">
      3.3.2基于法律法规、程序、诉讼或政府主管部门强制性要求下。
    </div>
    <div class="bold">3.4例外</div>
    <div class="bold">
      在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您和您的监护人的授权同意：
    </div>
    <div class="bold">3.4.1与国家安全、国防安全直接相关的；</div>
    <div class="bold">3.4.2与公共安全、公共卫生、重大公共利益直接相关的；</div>
    <div class="bold">3.4.3与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
    <div class="bold">
      3.4.4出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您和您的监护人同意的；
    </div>
    <div class="bold">3.4.5您或您的监护人自行向社会公众公开的个人信息；</div>
    <div class="bold">
      3.4.6从合法公开披露的信息中收集的个人信息，如合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="bold">
      3.4.7根据与您签订和履行的相关协议或其他书面文件所必需的；
    </div>
    <div class="bold">
      3.4.8对收集的个人信息已经进行匿名化或去标识化处理的；
    </div>
    <div class="bold">3.4.9为合法的新闻报道所必需的；</div>
    <div class="bold">
      3.4.10做学术研究用途，例如为科研机构开展统计或学术研究之必要
    </div>
    <div class="bold">3.4.11其他法律法规等规定的情形。</div>
    <br />
    <div class="bold">四、我们如何保护您的个人信息</div>
    <div>
      4.1
      为保障您的个人信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制等。
    </div>
    <div>
      4.2
      我们将按照《网络安全法》《儿童个人信息网络保护规定》等法律法规的要求保存您的个人信息。您的个人信息均储存于中华人民共和国境内（注：其中微信分享SDK绑定IP归属地为香港），如我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定及要求执行，并保证您的个人信息安全。
    </div>
    <div>
      4.3
      我们将保存您的个人信息不少于6个月，最长不超过20年。到期后，我们会将您的个人信息匿名化处理，以保证您的个人信息安全。
    </div>
    <div>
      <span class="bold"
        >4.4我们以最小授权为原则，仅允许有必要知晓您的个人信息的员工知晓您的个人信息，并为此设置了严格的访问权限控制和监控机制来控制您的个人信息知悉范围。</span
      >我们对可能接触到您的个人信息的员工或外包人员也采取了严格管理，包括但不限于经过儿童个人信息保护负责人或其授权的管理人员审批、根据岗位的不同采取不同的权限控制、与他们签署保密协议、监控他们的操作情况和记录访问情况等措施。我们建立了完善的信息安全管理制度和内部安全事件处置机制，我们会定期对负责人员及员工进行信息安全管理培训。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。
    </div>
    <div>
      4.5我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的个人信息不被泄漏、毁损或丢失。如不幸发生个人信息安全事件的，我们会立即启动应急预案，采取补救措施，并将按照法律法规的要求，及时向您告知安全事件的基本情况和可能产生的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </div>
    <div class="bold">
      4.6
      请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码等手段协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本文中提供的联系方式联系我们，以便我们及时采取相应措施来保护您的个人信息安全。
    </div>
    <br />
    <div class="bold">五、如何访问、更正、删除您的个人信息</div>
    <div>
      5.1
      在您使用青点互动平台服务期间，为了使您可以更加便捷地访问、更正、删除您的个人信息，同时保障您撤回对个人信息使用的同意及注销青点互动平台账户的权利。
    </div>
    <div>注销路径：我的-设置中心-账号安全-账号注销</div>
    <div>
      如果您不再希望使用我们的服务，您可以通过向我们发送邮件的方式申请注销账号，三个工作日内审核后即可注销成功，当您注销账号后，我们将立即删除有关您的相关信息，但法律法规另有规定的除外。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。请您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，我们将删除有关您的相关信息，但法律法规另有规定的除外。
    </div>
    <div>
      5.1.1在以下情形中，您可以向我们提出删除个人信息的请求：如果我们收集、使用您的个人信息的行为违反法律法规规定；如果我们收集、使用您的个人信息，却未征得您和您的监护人的明确同意；如果您和您的监护人撤回同意的；如果我们处理个人信息的行为严重违反了与您的约定；如果您不再使用我们的产品或服务，或您主动注销了账号。当我们协助您删除相关信息后，因为适用的法律、法规和安全技术的要求，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息，直到备份可以清除或实现匿名。
    </div>
    <div>
      5.1.2如您想撤回您的同意或更改相关功能的授权范围（例如：位置、相机、麦克风权限），您可以通过您的硬件设备修改相关设置。当您撤回同意或授权后，我们将无法继续为您提供撤回同意或授权所对应的服务。但您撤回同意或授权的决定不会影响此前基于您的同意或授权而已经开展的个人信息处理。
    </div>
    <br />
    <div class="bold">六、隐私政策的更新</div>
    <div>
      6.1 我们鼓励您在每次使用我们的服务时都查阅我们的关于儿童的隐私政策。
    </div>
    <div>
      6.2
      您理解并同意，为了更好的向您提供服务，我们需要适时根据青点互动平台产品及服务的发展情况，对本隐私政策进行修订及更新。我们会在青点互动平台显著位置发布更新后的隐私政策，<span
        class="bold"
        >如您不同意更新后的隐私政策内容，您应不使用或立即停止使用青点互动平台服务。</span
      >
    </div>
    <br />
    <div class="bold">七. 如何保护您孩子的个人信息</div>
    <div>
      7.1
      为保障您孩子的信息安全，我们会在现有技术水平下努力采取合理必要的物理、技术和行政管理方面的安全措施来保护您孩子的信息，以防止您孩子的信息遭受违法复制、丢失、误用、非授权访问、下载、披露和更改，包括但不限于SSL、信息加密存储、数据中心的访问控制、专用的网络通道及代理。
    </div>
    <div>
      7.2
      我们建立专门的管理制度、流程、组织并采取相应措施确保信息安全。对于工作人员，我们将以最小授权为原则，严格设定信息访问权限，控制儿童个人信息的知悉范围。如需访问儿童个人信息，我们将要求经过儿童个人信息保护负责人或其授权的管理人员审批，并对访问情况进行记录。此外，我们对可能接触到您孩子个人信息的员工或外包人员进行严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施；组织对员工的数据安全或合规培训等。
    </div>
    <div>
      7.3
      若我们确认发生个人信息泄露等安全事件，我们会立即启动应急预案，采取补救措施，阻止安全事件扩大，如涉及您孩子的个人信息，我们会将事件相关情况以邮件、信函、电话、推送通知等方式向您告知；如难以逐一告知，我们会采取公告的形式发布相关警示信息。同时，我们还将按照监管部门要求上报个人信息安全事件的处置情况。
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.children {
  word-break: break-all;
  word-wrap: break-word;
  padding-bottom: 20px;
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .date {
    margin-top: 80px;
    text-align: right;
  }
}
</style>
